import 'slick-carousel'
import Instagram from './instagram';

jQuery( document ).ready(function($) {

  // play video handler
  $('.play-button').click(function(){
    // console.log('clicked')
    const videoSource = $(this).parent().find('.videoSource').html()
    $(this).parent().find('.video-content').addClass('active')
    $(this).parent().find('.video-content .wrapper').html(videoSource)
  })
  
  $('.video-close').click(function(){
    $(this).parent().removeClass('active')
    $(this).parent().find('.video-content .wrapper').html('')
  })


  // slider
  $('#events-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></button>',
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  Instagram();

  $('.slick-next').click(function(){
    $('.runner').addClass('run-forward')
    $('.runner').removeClass('run-backward')
  })
  
  $('.slick-prev').click(function(){
    $('.runner').removeClass('run-forward')
    $('.runner').addClass('run-backward')
  })

});