export default function Instagram() {
	$.ajax( {
		url: 'https://www.juicer.io/api/feeds/adamdoleac',
		method: 'GET',
		dataType: 'json',
		success: data => {
			let html = '';
			let i = 0;
			for ( let item of data.posts.items ) {
				html += '<div>';
				html += '<a href="' + item.external_url + '" target="_blank">';
				html += '<img src="' + item.image + '" alt="' + item.id + '" />';
				html += '</a>';
				html += '</div>';

				i ++;
				if ( i >= 8 ) {
					break;
				}
			}

			$( '#instagram-posts' ).html( html );
		},
		error: () => {
			alert( 'Error fetching Instagram posts!' );
		}
	} );
}